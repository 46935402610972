import { Props } from '..';
import { cepMask } from '../../../../../../../utils/masks/cep';
import * as S from './styles';

export function AddressData({ collaborator }: Props) {
	return (
		<S.GridContainer>
			<S.InfoContainer>
				<S.InfoLabel>Endereço</S.InfoLabel>
				{collaborator.address}
			</S.InfoContainer>
			<S.InfoContainer>
				<S.InfoLabel>Número</S.InfoLabel>
				{collaborator.number}
			</S.InfoContainer>
			<S.InfoContainer>
				<S.InfoLabel>Bairro</S.InfoLabel>
				{collaborator.district}
			</S.InfoContainer>
			<S.InfoContainer>
				<S.InfoLabel>CEP</S.InfoLabel>
				{cepMask(collaborator.cep)}
			</S.InfoContainer>
			<S.InfoContainer>
				<S.InfoLabel>Complemento</S.InfoLabel>
				{collaborator.complement === '' ? 'N/A' : collaborator.complement}
			</S.InfoContainer>
			<S.InfoContainer>
				<S.InfoLabel>Referência</S.InfoLabel>
				{collaborator.reference ? collaborator.reference : 'N/A'}
			</S.InfoContainer>
			<S.InfoContainer>
				<S.InfoLabel>Cidade</S.InfoLabel>
				{collaborator.city}
			</S.InfoContainer>
			<S.InfoContainer>
				<S.InfoLabel>Estado</S.InfoLabel>
				{collaborator.uf}
			</S.InfoContainer>
		</S.GridContainer>
	);
}
