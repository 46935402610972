import { useParams } from 'react-router-dom';
import PageTitle from '../../../../../components/PageTitle';
import * as S from './styles';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { useQuery } from 'react-query';
import { getMigration } from '../../../../../services/queries/CollaboratorsMigrations';
import Loader from '../../../../../components/Loader';
import { parseDate } from '../../../../../utils/parseDate';

export function CollaboratorMigrationDetails() {
	const { id } = useParams<{ id: string }>();

	const fetchMigrationQuery = useQuery(
		['migration', id],
		() => {
			return getMigration(id!);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar a migração.'
				);
			},
			enabled: !!id,
			refetchOnWindowFocus: false,
		}
	);

	if (fetchMigrationQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Detalhamento de migração' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<div>
			<PageTitle title='Detalhamento de migração' />
			<S.GridContainer>
				<S.InfoContainer>
					<S.InfoLabel>Colaborador</S.InfoLabel>
					{`${fetchMigrationQuery.data?.collaborator.first_name} ${fetchMigrationQuery.data?.collaborator.last_name}`}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoLabel>Antiga empresa</S.InfoLabel>
					{fetchMigrationQuery.data?.current_company.name!}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoLabel>Nova empresa</S.InfoLabel>
					{fetchMigrationQuery.data?.new_company.name!}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoLabel>Antigo email</S.InfoLabel>
					{fetchMigrationQuery.data?.old_email}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoLabel>Novo email</S.InfoLabel>
					{fetchMigrationQuery.data?.new_email}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoLabel>Motivo da migração</S.InfoLabel>
					{fetchMigrationQuery.data?.reason_for_migration}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>Data da migração</S.InfoLabel>
					{parseDate(String(fetchMigrationQuery.data?.created_at), 0, 0)}
				</S.InfoContainer>
			</S.GridContainer>
		</div>
	);
}
