import * as TableStyles from '../../../../../components/Table/TableStyles';
import { TextWithHint } from '../../../../../components/TextWithHint';

interface Props {
    name: string;
    companies: number;
}

export function GroupCompanyTab({ name, companies }: Props) {
    return (
        <TableStyles.Table>
            <TableStyles.TableHeader>
                <TableStyles.TableRow>
                    <TableStyles.TableHeaderCell>
                        NOME
                    </TableStyles.TableHeaderCell>
                    <TableStyles.TableHeaderCell>
                        Nº DE EMPRESAS
                    </TableStyles.TableHeaderCell>
                </TableStyles.TableRow>
            </TableStyles.TableHeader>

            <TableStyles.TableBody>
                <TableStyles.TableRow>
                    <TableStyles.TableData>
                        <TextWithHint text={name} width='100%' />
                    </TableStyles.TableData>
                    <TableStyles.TableData>
                        {companies}
                    </TableStyles.TableData>
                </TableStyles.TableRow>
            </TableStyles.TableBody>
        </TableStyles.Table>
    );
}
