import styled from 'styled-components';
import { OptionsButton } from '../../../../../../components/Button';

export const openModalBttn = styled.button`
	background-color: var(--primary-blue);
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	padding: 0.8rem;
	margin: 0 auto;
	& svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 60vw;
	height: 80vh;

	max-width: 1500px;
	max-height: 1100px;

	padding: 1rem 0;
`;

export const TitleWrapper = styled.div`
	margin-bottom: 1rem;
`;

export const Bttn = styled.button`
	${OptionsButton}
	height: 4rem;
	margin-top: 1rem;
`;

export const BttnContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 1rem 0;
	column-gap: 1rem;
	margin-top: auto;
`;
