import { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import Modal from '../../../../../../components/Modal';
import * as S from './styles';

export interface FeedbackModalProps {
	onDeleteUser: (disapprovalReason: string) => void;
}

export default function FeedbackModal({ onDeleteUser }: FeedbackModalProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [disapprovalReason, setDisapprovalReason] = useState('');

	return (
		<>
			<S.Bttn onClick={() => setIsOpen(true)} data-testid='openButton_test_id'>
				Reprovar exclusão
			</S.Bttn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.ModalContainer data-testid='modal_container'>
					<FaInfoCircle />
					<span>{'Motivo da reprovação'}</span>

					<S.DisapprovalForm>
						<S.DisapprovalInput
							value={disapprovalReason}
							onChange={(e) => setDisapprovalReason(e.target.value)}
						/>
						<S.ConfirmButton
							onClick={() => {
								onDeleteUser(disapprovalReason);
								setIsOpen(false);
							}}
						>
							Enviar
						</S.ConfirmButton>
					</S.DisapprovalForm>
				</S.ModalContainer>
			</Modal>
		</>
	);
}
