import styled from 'styled-components';

export const openModalBttn = styled.button`
	background-color: var(--primary-blue);
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	padding: 0.8rem;
	margin: 0 auto;
	& svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}
`;

export const Container = styled.div`
	width: 70vw;
	height: 80vh;
	padding: 1rem 0;
`;

export const TitleWrapper = styled.div`
	margin-bottom: 1rem;
`;

export const LoadingContainer = styled.div`
	margin: 1rem 0;
`;
