import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getRequestPendingDeleteUsersCollaborator } from '../../../../../services/queries/RequestDeleteUsersCollaborator';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import * as S from './styles';
import * as TableStyles from '../../../../../components/Table/TableStyles';
import PageTitle from '../../../../../components/PageTitle';
import Loader from '../../../../../components/Loader';
import { Pagination } from '../../../../../components/Pagination';
import { SortColumnButton } from '../../../../../components/SortColumnButton';
import { EmptyContent } from '../../../../../components/EmptyContent';
import { RequestDeleteUserCollaboratorModal } from '../components/RequestsDetailsModal';
import { cpfMask } from '../../../../../utils/masks/cpf';
import { Filter } from '../../../../../components/Filter';
import { useRequestDeletePendingListStore } from '../../../../../stores/useRequestDeletePendingListStore';
import { useSetHistoryNonMatchCallbackLocation } from '../../../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { useSortColumnHook } from '../../../../../hooks/useSortColumnHook';

export function RequestsPending() {
	const queryClient = useQueryClient();
	const [currentPage, setCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();

	const [filterParams, setFiltersParams, resetFilters] =
		useRequestDeletePendingListStore((state) => [
			state.filterParams,
			state.setFiltersParams,
			state.resetFilters,
		]);
	useSetHistoryNonMatchCallbackLocation('requests', resetFilters);

	const fetchRequestDeleteQuery = useQuery(
		[
			'requestPendingDeleteCollabList',
			currentPage,
			filterParams,
			currentSortColumn,
		],
		() => {
			return getRequestPendingDeleteUsersCollaborator(
				currentPage,
				filterParams,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar os pedidos de remoção de colaboradores.'
				);
			},
		}
	);

	function refreshPage() {
		setCurrentPage(1);
		queryClient.resetQueries('requestPendingDeleteCollabList');
	}

	if (fetchRequestDeleteQuery.isLoading || !fetchRequestDeleteQuery.data) {
		return (
			<S.Container>
				<PageTitle title='Solicitações de exclusão pendentes' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<TableStyles.TableHeaderContainer>
				<PageTitle
					title='Solicitações de exclusão pendentes'
					totalRecords={fetchRequestDeleteQuery.data?.totalRequests}
				/>

				<Filter
					filterParams={filterParams}
					onFiltersChanged={(updatedFilters) => {
						setFiltersParams(updatedFilters);
						setCurrentPage(1);
					}}
				/>

				{/* Pagination spacing placeholder */}
				<div style={{ marginLeft: 'auto' }}></div>
				<Pagination
					onPageChange={(page) => setCurrentPage(page)}
					currentPage={currentPage}
					totalCount={fetchRequestDeleteQuery.data?.totalRequests!}
				/>
				<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyles.TableHeaderContainer>

			{fetchRequestDeleteQuery.data.requests.length > 0 ? (
				<TableStyles.Table>
					<TableStyles.TableHeader>
						<TableStyles.TableRow>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'company'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('company')}
								/>
								EMPRESA
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'name'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('name')}
								/>
								NOME COLABORADOR
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								CPF COLABORADOR
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>OPÇÕES</TableStyles.TableHeaderCell>
						</TableStyles.TableRow>
					</TableStyles.TableHeader>

					<TableStyles.TableBody>
						{fetchRequestDeleteQuery.data.requests.map((request) => (
							<TableStyles.TableRow key={request.id}>
								<TableStyles.TableData>
									{request.company.name}
								</TableStyles.TableData>
								<TableStyles.TableData>
									{`${request.user.first_name} ${request.user.last_name}`}
								</TableStyles.TableData>
								<TableStyles.TableData>
									{cpfMask(request.user.cpf)}
								</TableStyles.TableData>
								<TableStyles.TableData>
									<S.OptionsContainer>
										<RequestDeleteUserCollaboratorModal request={request} />
									</S.OptionsContainer>
								</TableStyles.TableData>
							</TableStyles.TableRow>
						))}
					</TableStyles.TableBody>
				</TableStyles.Table>
			) : (
				<EmptyContent big />
			)}
		</S.Container>
	);
}
