import styled from 'styled-components';
import { OptionsButton } from '../../../../../../../../components/Button';

export const Container = styled.div`
	width: 30rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	& svg {
		color: var(--terciary);
		width: 4rem;
		height: 4rem;
	}
`;

export const Message = styled.p`
	text-align: center;
	font-size: 1.4rem;
	margin: 2rem 0;
`;

export const OptionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	margin-top: 1.5rem;
`;

export const ModelBttn = styled.button`
	${OptionsButton}
	margin-top: 2rem;
	height: 4rem;
	width: fit-content;
`;
