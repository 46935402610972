import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

export const OptionsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: 2rem 0;
	width: 100%;
	height: 15rem;
	& > div {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
	}
`;

export const OptionSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 48%;
	min-height: 11rem;
	max-height: 11rem;
`;

export const OptionTitle = styled.label`
	width: 100%;
	font-size: 1.8rem;
	font-weight: 500;
`;

export const NoOptionSelectedMessage = styled.span`
	font-size: 1.4rem;
	margin-bottom: 1.8rem;
`;

export const InputsContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	width: 100%;
	margin-bottom: 2rem;
	margin-top: 2rem;
`;

export const Inputs = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 50%;
`;

export const Submit = styled.button`
	${OptionsButton}
	margin-bottom: 2rem;
`;

export const ObrigatoryIndicator = styled.span`
	color: var(--primary-red);
	font-size: 1.2rem;
	font-weight: normal;
`;

export const NoObrigatoryIndicator = styled.span`
	color: var(--primary-blue);
	font-size: 1.2rem;
	font-weight: normal;
	width: 50%;
	margin-top: 1rem;
	text-align: justify;
`;

export const Warning = styled.span`
	font-family: 'Poppins', sans-serif;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 2.1rem;
	text-align: center;
	color: #929292;
`;
