import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { EmptyContent } from '../../../../components/EmptyContent';
import { Filter, FilterParams } from '../../../../components/Filter';

import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import { Pagination } from '../../../../components/Pagination';
import { SortColumnButton } from '../../../../components/SortColumnButton';
import * as TableStyles from '../../../../components/Table/TableStyles';
import { TextWithHint } from '../../../../components/TextWithHint';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { getCompaniesSelect } from '../../../../services/queries/Companies';
import { getOperationalReport } from '../../../../services/queries/OperationalReport';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { cpfMask } from '../../../../utils/masks/cpf';
import { ReportGenerator } from './ReportGenerator';
import * as S from './styles';
import { parseCardStatus } from './util/parseCardStatus';
import { useCollaboratorsListStore } from '../../../../stores/useCollaboratorsListStore';
import { Link } from 'react-router-dom';
import { useOperationalReportListStore } from '../../../../stores/useOperationalReportListStore';
import { useSetHistoryNonMatchCallbackLocation } from '../../../../hooks/useSetHistoryNonMatchCallbackLocation';

export function OperationalReport() {
	const queryClient = useQueryClient();
	const [currentPage, setCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const setCollaboratorsListFilterParams = useCollaboratorsListStore(
		(state) => state.setFiltersParams
	);

	const [filterParams, setFilterParams, resetFilters] =
		useOperationalReportListStore((state) => [
			state.filterParams,
			state.setFiltersParams,
			state.resetFilters,
		]);
	useSetHistoryNonMatchCallbackLocation('operationalReport', resetFilters);

	const fetchCompaniesQuery = useQuery(
		['companiesListSelect', currentPage, filterParams, currentSortColumn],
		() => {
			return getCompaniesSelect();
		},
		{
			onSuccess: (data) => {
				const updated_filters = filterParams.map((filter) => {
					if (filter.type === 'company' && filter.value === '') {
						return { ...filter, value: data[0].name };
					}
					return filter;
				});
				setFilterParams(updated_filters);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as empresas.'
				);
			},
			enabled: true,
			refetchOnWindowFocus: false,
		}
	);

	const fetchOperationalReportsQuery = useQuery(
		['operationalReport', currentPage, filterParams, currentSortColumn],
		() => {
			return getOperationalReport(currentPage, filterParams, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o relatorio operacional.'
				);
			},
			enabled: fetchCompaniesQuery.isSuccess,
			refetchOnWindowFocus: false,
		}
	);

	function refreshPage() {
		setCurrentPage(1);
		queryClient.resetQueries('operationalReport');
	}

	if (fetchOperationalReportsQuery.isLoading || fetchCompaniesQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Relatório Operacional' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<TableStyles.TableHeaderContainer>
				<PageTitle
					title='Relatório Operacional'
					totalRecords={fetchOperationalReportsQuery.data?.totalCount!}
				/>

				<Filter
					filterParams={filterParams}
					onFiltersChanged={(updatedFilters) => {
						setFilterParams(updatedFilters);
						setCurrentPage(1);
					}}
				/>

				{/* Pagination spacing placeholder */}
				<div style={{ marginLeft: 'auto' }}></div>
				<Pagination
					onPageChange={(page) => setCurrentPage(page)}
					currentPage={currentPage}
					totalCount={fetchOperationalReportsQuery.data?.totalCount!}
				/>
				<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyles.TableHeaderContainer>

			{fetchOperationalReportsQuery.data?.collaborators.length! > 0 ? (
				<>
					<TableStyles.Table>
						<TableStyles.TableHeader>
							<TableStyles.TableRow>
								<TableStyles.TableHeaderCell>
									<SortColumnButton
										order={
											currentSortColumn?.name === 'name'
												? currentSortColumn.order
												: null
										}
										onToggleSort={() => toggleSort('name')}
									/>
									NOME
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>CPF</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									PRÓXIMO AGENDAMENTO
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									ÚLTIMA RECARGA
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									ÚLTIMA TRANSAÇÃO
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									STATUS DO CARTÃO
								</TableStyles.TableHeaderCell>
							</TableStyles.TableRow>
						</TableStyles.TableHeader>

						<TableStyles.TableBody>
							{fetchOperationalReportsQuery.data?.collaborators.map(
								(collaborator) => (
									<TableStyles.TableRow key={collaborator.id}>
										<TableStyles.TableData>
											<TextWithHint
												text={`${collaborator.first_name} ${collaborator.last_name}`}
												width='18rem'
											/>
										</TableStyles.TableData>
										<TableStyles.TableData>
											<Link
												to={`/collaborators`}
												onClick={() => {
													const cpfFilter: FilterParams = {
														name: 'CPF',
														filter: 'cpf',
														value: collaborator.cpf,
														type: 'cpf',
														selected: true,
													};

													const updatedFilters = [cpfFilter];

													setCollaboratorsListFilterParams(updatedFilters);
												}}
											>
												{cpfMask(collaborator.cpf)}
											</Link>
										</TableStyles.TableData>
										<TableStyles.TableData>
											{collaborator.scheduled_date
												? new Date(collaborator.scheduled_date).toLocaleString()
												: 'Sem agendamento'}
										</TableStyles.TableData>
										<TableStyles.TableData>
											{collaborator.release_date
												? new Date(collaborator.release_date).toLocaleString()
												: 'Sem recarga anterior'}
										</TableStyles.TableData>
										<TableStyles.TableData>
											{collaborator.transaction_date
												? new Date(
														collaborator.transaction_date
												  ).toLocaleString()
												: 'Sem transação executada'}
										</TableStyles.TableData>
										<TableStyles.TableData>
											{parseCardStatus(collaborator.card_status)}
										</TableStyles.TableData>
									</TableStyles.TableRow>
								)
							)}
						</TableStyles.TableBody>
					</TableStyles.Table>

					<S.ReportContainer>
						<ReportGenerator
							filterParams={filterParams}
							sortColumn={currentSortColumn}
						/>
					</S.ReportContainer>
				</>
			) : (
				<EmptyContent big />
			)}
		</S.Container>
	);
}
