export type BenefitsUserAccessLevel = 'operator' | 'super_admin' | 'admin';

const BENEFITS_ACCESS_LEVELS: Record<BenefitsUserAccessLevel, string> = {
	operator: 'Operador',
	super_admin: 'Super Admin',
	admin: 'Admin',
};

export function parseBenefitsAccessLevel(accessLevel: BenefitsUserAccessLevel | string): string {
	return BENEFITS_ACCESS_LEVELS[accessLevel as BenefitsUserAccessLevel] || '???';
}
