import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { EmptyContent } from '../../../components/EmptyContent';
import { Filter } from '../../../components/Filter';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import { Pagination } from '../../../components/Pagination';
import * as TableStyles from '../../../components/Table/TableStyles';
import { TextWithHint } from '../../../components/TextWithHint';
import { getCompanies } from '../../../services/queries/Companies';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { cnpjMask } from '../../../utils/masks/cnpj';
import { parseKYCStatusString } from '../../../utils/status/parseKycStatus';
import { parseStatus } from '../../../utils/status/parseStatus';
import { CompanyDetailsModal } from './CompanyDetailsModal';
import * as S from './styles';
import { useCompaniesListStore } from '../../../stores/useCompaniesListStore';
import { useSetHistoryNonMatchCallbackLocation } from '../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook/index';
import { SortColumnButton } from '../../../components/SortColumnButton';
import { GroupCompanyDetails } from './GroupCompanyDetails';

export function CompaniesList() {
	const queryClient = useQueryClient();
	const [currentPage, setCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [filtersParams, setFiltersParams, resetFilters] = useCompaniesListStore(
		(state) => [state.filterParams, state.setFiltersParams, state.resetFilters]
	);
	useSetHistoryNonMatchCallbackLocation('companies', resetFilters);

	const fetchCompaniesQuery = useQuery(
		['companiesList', currentPage, filtersParams, currentSortColumn],
		() => {
			return getCompanies(currentPage, filtersParams, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as empresas.'
				);
			},
		}
	);

	function refreshPage() {
		setCurrentPage(1);
		queryClient.resetQueries('companiesList');
	}

	if (fetchCompaniesQuery.isLoading || !fetchCompaniesQuery.data)
		return (
			<S.Container>
				<PageTitle title='Empresas' />
				<Loader />
			</S.Container>
		);
	return (
		<S.Container>
			<TableStyles.TableHeaderContainer>
				<PageTitle
					title='Empresas'
					totalRecords={fetchCompaniesQuery.data?.totalCompanies}
				/>

				<Filter
					filterParams={filtersParams}
					onFiltersChanged={(updatedFilters) => {
						setFiltersParams(updatedFilters);
						setCurrentPage(1);
					}}
				/>

				{/* Pagination spacing placeholder */}
				<div style={{ marginLeft: 'auto' }}></div>
				<Pagination
					onPageChange={(page) => setCurrentPage(page)}
					currentPage={currentPage}
					totalCount={fetchCompaniesQuery.data?.totalCompanies!}
				/>
				<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyles.TableHeaderContainer>

			{fetchCompaniesQuery.data.companies.length > 0 ? (
				<TableStyles.Table>
					<TableStyles.TableHeader>
						<TableStyles.TableRow>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'name'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('name')}
								/>
								NOME
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>CNPJ</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								KYC STATUS
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>STATUS</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>PRODUTO</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell style={{ textAlign: 'center' }}>
								OPÇÕES
							</TableStyles.TableHeaderCell>
						</TableStyles.TableRow>
					</TableStyles.TableHeader>

					<TableStyles.TableBody>
						{fetchCompaniesQuery.data.companies.map((company) => (
							<TableStyles.TableRow key={company.id}>
								<TableStyles.TableData>
									<TextWithHint text={company.name} width='18rem' />
								</TableStyles.TableData>
								<TableStyles.TableData>
									<S.cnpjText>{cnpjMask(company.cnpj)}</S.cnpjText>
								</TableStyles.TableData>
								<TableStyles.TableData>
									{parseKYCStatusString(company.kyc_status)}
								</TableStyles.TableData>
								<TableStyles.TableData>
									{parseStatus(company.status)}
								</TableStyles.TableData>
								<TableStyles.TableData>
									{company.company_products
										?.product_corporate_expenses_enabled &&
									company.company_products
										?.product_flexible_benefits_enabled ? (
										<>
											<span
												style={{ color: 'var(--primary-red)', fontWeight: 600 }}
											>
												Benefícios Flexíveis
											</span>
											<span
												style={{
													color: 'var(--primary-blue)',
													fontWeight: 600,
												}}
											>
												Control
											</span>
										</>
									) : company.company_products
											?.product_corporate_expenses_enabled ? (
										<span
											style={{ color: 'var(--primary-blue)', fontWeight: 600 }}
										>
											Control
										</span>
									) : company.company_products
											?.product_flexible_benefits_enabled ? (
										<span
											style={{ color: 'var(--primary-red)', fontWeight: 600 }}
										>
											Benefícios Flexíveis
										</span>
									) : (
										<span style={{ fontWeight: 400, fontSize: '1.5rem' }}>
											N/A
										</span>
									)}
								</TableStyles.TableData>

								<TableStyles.TableData>
									<S.OptionsContainer>
										<CompanyDetailsModal company={company} />
										<GroupCompanyDetails corporate_group_id={company.corporate_group_id!}  company_id={company.id!} />
									</S.OptionsContainer>
								</TableStyles.TableData>
							</TableStyles.TableRow>
						))}
					</TableStyles.TableBody>
				</TableStyles.Table>
			) : (
				<EmptyContent big />
			)}
		</S.Container>
	);
}
