import { SelectOption } from '..';
import RESPONSE_CODES from '../../../pages/Main/FlexibleBenefits/Collaborators/Transactions/constants/RESPONSE_CODE.json';
import MCCS from '../../../pages/Main/FlexibleBenefits/Collaborators/Transactions/constants/MCCS.json';
import TXN_TYPE from '../../../pages/Main/FlexibleBenefits/Collaborators/Transactions/constants/TXN_TYPE.json';

export const KYCStatusOptionsFilters: SelectOption[] = [
	{
		text: 'Aprovado',
		value: 'approved',
	},
	{
		text: 'Reprovado',
		value: 'reproved',
	},
	{
		text: 'Em análise',
		value: 'in_analysis',
	},
];

export const StatusOptionsFilters: SelectOption[] = [
	{
		text: 'Ativados',
		value: 'true',
	},
	{
		text: 'Desativados',
		value: 'false',
	},
];

export const ContractTypeOptionsFilters: SelectOption[] = [
	{
		text: 'CLT',
		value: 'CLT',
	},
	{
		text: 'Estágio',
		value: 'TRAINEE',
	},
	{
		text: 'Sócio',
		value: 'SHAREHOLDER',
	},
	{
		text: 'PJ',
		value: 'PJ',
	},
];

export const PaymentTypeOptionsFilters: SelectOption[] = [
	{
		text: 'Possui recarga',
		value: 'payment',
	},
	{
		text: 'Não possui recarga',
		value: 'no_payment',
	},
];

export const ScheduleTypeOptionsFilters: SelectOption[] = [
	{
		text: 'Possui agendamento',
		value: 'schedule',
	},
	{
		text: 'Não possui agendamento',
		value: 'no_schedule',
	},
];

export const TransactionTypeOptionsFilters: SelectOption[] = [
	{
		text: 'Possui transação',
		value: 'transaction',
	},
	{
		text: 'Não possui transação',
		value: 'no_transaction',
	},
];

export const CardStatusTypeOptionsFilters: SelectOption[] = [
	{
		text: 'Cartão vinculado',
		value: 'linked',
	},
	{
		text: 'Cartão solicitado',
		value: 'requested',
	},
	{
		text: 'Sem cartão',
		value: 'none',
	},
];

export const ReleasesTypeOptionsFilters: SelectOption[] = [
	{ text: 'Cancelado', value: 'canceled' },
	{ text: 'Finalizado', value: 'approved' },
	{ text: 'Reprovado', value: 'disapproved' },
	{ text: 'Pendente', value: 'requested' },
	{ text: 'Processando', value: 'processing' },
	{ text: 'Agendado', value: 'scheduled' },
	{ text: 'Finalizado com erro', value: 'finished error' },
	{ text: 'Estornado', value: 'chargeback-success' },
	{ text: 'Processando estorno', value: 'chargeback-processing' },
	{ text: 'Estorno parcial', value: 'chargeback-partial' },
	{ text: 'Estornado com erro', value: 'chargeback-error' },
];

export const ResponseCodeOptionsFilters: SelectOption[] = Object.entries(
	RESPONSE_CODES
).map(([key, value]) => {
	return {
		text: `${value.msg}(${key})`,
		value: key,
	};
});

export const MCCOptionsFilters: SelectOption[] = MCCS.map((mcc) => {
	return {
		text: `${mcc.Descricao}(${mcc.CodigoMCC})`,
		value: mcc.CodigoMCC,
	};
});

export const TxnTypesOptionsFilters: SelectOption[] = Object.entries(
	TXN_TYPE
).map(([key, value]) => {
	return {
		text: `${key}(${value})`,
		value: key,
	};
});

export const ResponseTypesOptionsFilters: SelectOption[] = Object.entries(
	RESPONSE_CODES
).map(([key, value]) => {
	const statusText = value.status === 'Approved' ? 'APROVADO' : 'NEGADO';
	return {
		text: `${key} (${value.msg}) (${statusText})`,
		value: key,
	};
});

export const FinancialImpactOptionsFilters: SelectOption[] = [
	{ text: 'Débito', value: 'DEBIT' },
	{ text: 'Crédito', value: 'CREDIT' },
	{ text: 'Nenhum', value: 'NONE' },
];

export const StatusRequestsAcceptsOptionsFilters: SelectOption[] = [
	{
		text: 'Aprovados',
		value: 'true',
	},
	{
		text: 'Reprovados',
		value: 'false',
	},
];
