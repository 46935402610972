import { Operator } from '../../../../../@types/Operator';
import { EmptyContent } from '../../../../../components/EmptyContent';
import { SortColumnButton } from '../../../../../components/SortColumnButton';
import * as TableStyles from '../../../../../components/Table/TableStyles';
import { TextWithHint } from '../../../../../components/TextWithHint';
import { useSortColumnHook } from '../../../../../hooks/useSortColumnHook';
import { parseBenefitsAccessLevel } from '../../../../../utils/benefits/parseUserAccessLevel';
import { CorpwayUserAccessLevel, parseUserCorpwayAccessLevel } from '../../../../../utils/corpway/parseUserCorpwayAccessLevel';

interface OperatorListProps {
    operators: Operator[];
}

export function OperatorsTab({ operators }: OperatorListProps) {
    const { currentSortColumn, toggleSort, sortList } = useSortColumnHook();

    function parseAccessLevels(operator: Operator): { corpwayAccess: string; benefitsAccess: string } {
        const corpwayAccess = operator.corpway_access_level && isCorpwayUserAccessLevel(operator.corpway_access_level)
            ? parseUserCorpwayAccessLevel(operator.corpway_access_level)
            : '';

        const benefitsAccess = operator.access_level
            ? parseBenefitsAccessLevel(operator.access_level)
            : '';

        return { corpwayAccess, benefitsAccess };
    }


    function isCorpwayUserAccessLevel(value: string): value is CorpwayUserAccessLevel {
        return ['general_admin', 'account_manager', 'finance', 'card_responsible', 'custom'].includes(value);
    }

    if (operators.length === 0) return <EmptyContent big />;

    return (
        <TableStyles.Table>
            <TableStyles.TableHeader>
                <TableStyles.TableRow>
                    <TableStyles.TableHeaderCell>
                        <SortColumnButton
                            order={
                                currentSortColumn?.name === 'name'
                                    ? currentSortColumn.order
                                    : null
                            }
                            onToggleSort={() => toggleSort('name')}
                        />
                        NOME
                    </TableStyles.TableHeaderCell>
                    <TableStyles.TableHeaderCell>
                        <SortColumnButton
                            order={
                                currentSortColumn?.name === 'email'
                                    ? currentSortColumn.order
                                    : null
                            }
                            onToggleSort={() => toggleSort('email')}
                        />
                        EMAIL
                    </TableStyles.TableHeaderCell>
                    <TableStyles.TableHeaderCell>
                        <SortColumnButton
                            order={
                                currentSortColumn?.name === 'created_at'
                                    ? currentSortColumn.order
                                    : null
                            }
                            onToggleSort={() => toggleSort('created_at')}
                        />
                        CRIAÇÃO
                    </TableStyles.TableHeaderCell>
                    <TableStyles.TableHeaderCell>
                        NÍVEL DE ACESSO
                    </TableStyles.TableHeaderCell>
                </TableStyles.TableRow>
            </TableStyles.TableHeader>

            <TableStyles.TableBody>
                {sortList(operators).map((operator) => {
                    const { corpwayAccess, benefitsAccess } = parseAccessLevels(operator);
                    return (
                        <TableStyles.TableRow key={operator.id}>
                            <TableStyles.TableData>
                                <TextWithHint text={operator.name} width='100%' />
                            </TableStyles.TableData>
                            <TableStyles.TableData>
                                <TextWithHint text={operator.email} width='100%' />
                            </TableStyles.TableData>
                            <TableStyles.TableData>
                                {operator.created_at
                                    ? new Date(operator.created_at).toLocaleDateString()
                                    : 'Não informado'}
                            </TableStyles.TableData>
                            <TableStyles.TableData>
                                {corpwayAccess !== '' &&
                                    benefitsAccess !== '' ? (
                                    <>
                                        <span
                                            style={{ color: 'var(--primary-red)', fontWeight: 400 }}
                                        >
                                            {benefitsAccess}
                                        </span>
                                        <span
                                            style={{
                                                color: 'var(--primary-blue)',
                                                fontWeight: 400,
                                            }}
                                        >
                                            {corpwayAccess}
                                        </span>
                                    </>
                                ) : corpwayAccess !== '' ? (
                                    <span
                                        style={{ color: 'var(--primary-blue)', fontWeight: 400 }}
                                    >
                                        {corpwayAccess}
                                    </span>
                                ) : benefitsAccess !== '' ? (
                                    <span
                                        style={{ color: 'var(--primary-red)', fontWeight: 400 }}
                                    >
                                        {benefitsAccess}
                                    </span>
                                ) : (
                                    <span style={{ fontWeight: 400, fontSize: '1.5rem' }}>
                                        N/A
                                    </span>
                                )}
                            </TableStyles.TableData>
                        </TableStyles.TableRow>
                    );
                })}
            </TableStyles.TableBody>
        </TableStyles.Table>
    );
}
