import * as TableStyles from '../../../../components/Table/TableStyles';
import { useQuery, useQueryClient } from 'react-query';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { useState } from 'react';
import PageTitle from '../../../../components/PageTitle';
import Loader from '../../../../components/Loader';
import { getMigrations } from '../../../../services/queries/CollaboratorsMigrations';
import { useMigrationsListStore } from '../../../../stores/useMigrationsListStore';
import * as S from './styles';
import { Filter } from '../../../../components/Filter';
import { Pagination } from '../../../../components/Pagination';
import { EmptyContent } from '../../../../components/EmptyContent';
import { TextWithHint } from '../../../../components/TextWithHint';
import { MdNotes } from 'react-icons/md';

export function CollaboratorsMigrations() {
	const queryClient = useQueryClient();
	const [currentPage, setCurrentPage] = useState(1);
	const [filtersParams, setFiltersParams] = useMigrationsListStore((state) => [
		state.filterParams,
		state.setFiltersParams,
		state.resetFilters,
	]);

	const fetchMigrationsQuery = useQuery(
		['migrationsList', currentPage, filtersParams],
		() => {
			return getMigrations(currentPage, filtersParams);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as migrações.'
				);
			},
		}
	);

	function refreshPage() {
		setCurrentPage(1);
		queryClient.resetQueries('migrationsList');
	}

	if (fetchMigrationsQuery.isLoading || !fetchMigrationsQuery.data)
		return (
			<S.Container>
				<PageTitle title='Migrações de colaboradores' />
				<Loader />
			</S.Container>
		);

	return (
		<S.Container>
			<TableStyles.TableHeaderContainer>
				<PageTitle
					title='Migrações de colaboradores'
					totalRecords={fetchMigrationsQuery.data.totalMigrations}
				/>

				<Filter
					filterParams={filtersParams}
					onFiltersChanged={(updatedFilters) => {
						setFiltersParams(updatedFilters);
						setCurrentPage(1);
					}}
				/>

				{/* Pagination spacing placeholder */}
				<div style={{ marginLeft: 'auto' }}></div>
				<Pagination
					onPageChange={(page) => setCurrentPage(page)}
					currentPage={currentPage}
					totalCount={fetchMigrationsQuery.data?.totalMigrations!}
				/>
				<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyles.TableHeaderContainer>

			{fetchMigrationsQuery.data.migrations.length > 0 ? (
				<>
					<TableStyles.Table>
						<TableStyles.TableHeader>
							<TableStyles.TableRow>
								<TableStyles.TableHeaderCell>
									COLABORADOR
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									ANTIGO EMAIL
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									NOVO EMAIL
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									ANTIGA EMPRESA
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									NOVA EMPRESA
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell style={{ textAlign: 'center' }}>
									OPÇÕES
								</TableStyles.TableHeaderCell>
							</TableStyles.TableRow>
						</TableStyles.TableHeader>

						<TableStyles.TableBody>
							{fetchMigrationsQuery.data.migrations.map((migration) => (
								<TableStyles.TableRow key={migration.id}>
									<TableStyles.TableData>
										<TextWithHint
											text={`${migration.collaborator.first_name} ${migration.collaborator.last_name}`}
											width='18rem'
										/>
									</TableStyles.TableData>
									<TableStyles.TableData>
										<TextWithHint text={migration.old_email} width='22rem' />
									</TableStyles.TableData>
									<TableStyles.TableData>
										<TextWithHint text={migration.new_email} width='22rem' />
									</TableStyles.TableData>
									<TableStyles.TableData>
										<TextWithHint
											text={migration.current_company.name}
											width='18rem'
										/>
									</TableStyles.TableData>
									<TableStyles.TableData>
										<TextWithHint
											text={migration.new_company.name}
											width='18rem'
										/>
									</TableStyles.TableData>
									<TableStyles.TableData>
										<TableStyles.Option
											data-rh='Detalhar migração'
											to={`/collaborators-migrations/${migration.id}`}
										>
											<MdNotes />
										</TableStyles.Option>
									</TableStyles.TableData>
								</TableStyles.TableRow>
							))}
						</TableStyles.TableBody>
					</TableStyles.Table>
				</>
			) : (
				<EmptyContent big />
			)}

			<S.CreateMigrationButton to={'/collaborators-migrations/create'}>
				Criar Migração
			</S.CreateMigrationButton>
		</S.Container>
	);
}
