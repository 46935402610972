import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	overflow: auto;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;

export const FooterContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 4rem;
	margin: 1rem 0;
`;

export const SubmitionOptionsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: auto;
	width: fit-content;
`;

export const CancellButton = styled.button`
	${OptionsButton}
	background: none;
	background-color: var(--white);
	color: var(--primary-blue);
	margin-right: 1rem;
`;

export const EditPaymentLink = styled(Link)`
	${OptionsButton}
	font-size: 1.4rem;
`;

export const RelaunchPaymentButton = styled.button`
	${OptionsButton}
`;

export const SubmitButton = styled.button`
	${OptionsButton}
	background-color: var(--primary-blue);
	color: var(--white);
`;

export const UnderlinedButton = styled.button`
	background: none;
	border: none;
	font: inherit;
	cursor: pointer;
	text-decoration: underline;
	color: var(--primary-blue);
	font-size: 1.4rem;
	margin-left: auto;
`;
