import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div``;

export const CreateMigrationButton = styled(Link)`
	${OptionsButton}
	font-size: 1.4rem;
`;
