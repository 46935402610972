import { toast } from 'react-toastify';
import Loader from '../../../../../../components/Loader';
import {
	PDFstyles,
	ReportButton,
} from '../../../../../../components/PDFReportStyles';
import {
	Document,
	Page,
	Text,
	View,
	Font,
	pdf,
	Image,
} from '@react-pdf/renderer';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { useQuery } from 'react-query';
import { getCollaboratorTransactionsV3ToReport } from '../../../../../../services/queries/Collaborators';
import { FilterParams } from '../../../../../../components/Filter';
import { SortColumn } from '../../../../../../hooks/useSortColumnHook';
import RESPONSE_CODES from '../../Transactions/constants/RESPONSE_CODE.json';
import { parseTransactionStatus } from '../../../../../../utils/status/parseStatus';
import {
	convertCentsToCurrency,
	convertCentsToReais,
} from '../../../../../../utils/CurrencyConvert';
import BountyLogo from '../../../../../../assets/FlexibleBenefitsLogo.png';
import ReportIcon from '../../../../../../assets/ReportIcon.png';
import { cpfMask } from '../../../../../../utils/masks/cpf';
import { getTransactionType } from '../../../../../../utils/transactionUtils';

Font.register({
	family: 'Ubuntu',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eAT3v02QFg.ttf',
		},
	],
});

export interface ReportProps {
	filterParams: FilterParams[];
	sortColumn: SortColumn | null;
	collaborator: {
		collaborator_name: string;
		collaborator_id: string;
		collaborator_cpf: string;
	};
}

export function PdfReportGenerator({
	collaborator,
	filterParams,
	sortColumn,
}: ReportProps) {
	const fetchOperationalReportsQuery = useQuery(
		['operationalReportToPDF', collaborator, filterParams, sortColumn],
		() => {
			return getCollaboratorTransactionsV3ToReport(
				collaborator.collaborator_id,
				filterParams,
				sortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as transações para o relatório.'
				);
			},
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	async function handleGeneratePDF() {
		try {
			const PDFGenerated = await generatePDFContent();
			const blob = await pdf(PDFGenerated).toBlob();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = `relatorio_transações_${collaborator.collaborator_name}.pdf`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relátorio. Tente novamente'
			);
		}
	}

	async function generatePDFContent() {
		const reportFetching = await fetchOperationalReportsQuery.refetch();
		const { totalTransactions, transactions, totalValue, totalReports } =
			reportFetching.data!;

		let filters: { name: string; value: string; second_value?: string }[] = [];

		filterParams.forEach((filter) => {
			if (filter.selected) {
				filters.push({
					name: filter.name,
					value: filter.value,
					second_value: filter.second_value,
				});
			}
		});

		return (
			<Document>
				<Page size='A4' style={PDFstyles.page} orientation='landscape'>
					<View style={PDFstyles.header} fixed>
						<Image src={BountyLogo} style={PDFstyles.logo} />

						<View style={PDFstyles.headerInfo}>
							<Text style={PDFstyles.headerInfoLabel}>Nome:</Text>
							<Text style={PDFstyles.headerInfoData}>
								{collaborator.collaborator_name}
							</Text>
						</View>
						<View style={PDFstyles.headerInfo}>
							<Text style={PDFstyles.headerInfoLabel}>CPF:</Text>
							<Text style={PDFstyles.headerInfoData}>
								{cpfMask(collaborator.collaborator_cpf)}
							</Text>
						</View>
						<View style={PDFstyles.headerInfo}>
							<Text style={PDFstyles.headerInfoLabel}>N° Página:</Text>
							<Text
								style={PDFstyles.headerInfoData}
								render={({ pageNumber, totalPages }) =>
									`${String(pageNumber).padStart(2, '0')}/${String(
										totalPages
									).padStart(2, '0')}`
								}
							></Text>
						</View>
						<View style={PDFstyles.headerInfo}>
							<Text style={PDFstyles.headerInfoLabel}>Data do pedido:</Text>
							<Text style={PDFstyles.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									hour12: false,
								}).format(new Date())}
							</Text>
						</View>

						<View style={{ ...PDFstyles.headerInfo, width: '120%' }}>
							<Text style={PDFstyles.headerInfoLabel}>Intervalo de busca:</Text>
							<Text style={PDFstyles.headerInfoData}>Todo o período</Text>
						</View>

						<View style={{ ...PDFstyles.headerInfo, width: '60%' }}>
							<Text style={PDFstyles.headerInfoLabel}>N° do pedido:</Text>
							<Text style={PDFstyles.headerInfoData}>
								{String(totalReports).padStart(7, '0')}
							</Text>
						</View>
					</View>

					<View style={PDFstyles.subHeader}>
						<View style={PDFstyles.titleContainer}>
							<Image src={ReportIcon} style={PDFstyles.reportLogo} />
							<View style={PDFstyles.reportTitleContainer}>
								<Text
									style={{
										...PDFstyles.reportTitle,
										color: '#FF0037',
									}}
								>
									Relatório de
								</Text>

								<Text> </Text>

								<Text style={PDFstyles.reportTitle}>transações</Text>
							</View>
						</View>

						<View style={PDFstyles.subHeaderDataContainer}>
							<View style={{ ...PDFstyles.subHeaderInfo }}>
								<Text style={PDFstyles.subHeaderLabel}>
									Valor total das transações:
								</Text>
								<Text style={PDFstyles.subHeaderData}>
									{convertCentsToReais(Number(totalValue))}
								</Text>
							</View>

							<View style={{ ...PDFstyles.subHeaderInfo }}>
								<Text style={PDFstyles.subHeaderLabel}>
									Total de registros:
								</Text>
								<Text style={PDFstyles.subHeaderData}>{totalTransactions}</Text>
							</View>
						</View>
					</View>

					<View style={PDFstyles.tableContainer}>
						<View style={PDFstyles.tableHeader}>
							<View
								style={{
									...PDFstyles.columnTitleContainer,
									width: '20%',
								}}
							>
								<Text style={PDFstyles.columnTitle}>Nº</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Categoria</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>MCC</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Status</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Código de status</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Estabelecimento</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Data da tentativa</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Valor</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Cartão</Text>
							</View>
						</View>

						{transactions.map((t, i) => (
							<View style={PDFstyles.tableRow} key={i} wrap={false}>
								<Text
									style={{
										...PDFstyles.columnData,
										width: '20%',
									}}
								>
									{String(i + 1)}
								</Text>

								<Text
									style={{
										...PDFstyles.columnData,
										// width: '60%'
									}}
								>
									{ getTransactionType(t.txn_type, t.msg_type) }
								</Text>

								<Text style={PDFstyles.columnData}>{t.mcc || 'N/A'}</Text>

								<Text style={PDFstyles.columnData}>
									{parseTransactionStatus(
										RESPONSE_CODES[
											String(t.response_code) as keyof typeof RESPONSE_CODES
										].status
									)}
								</Text>

								<Text style={PDFstyles.columnData}>
									{`${
										RESPONSE_CODES[
											String(t.response_code) as keyof typeof RESPONSE_CODES
										].msg
									} - (${t.response_code})`}
								</Text>

								<Text style={PDFstyles.columnData}>
									{t.merchant_name || 'N/A'}
								</Text>

								<Text style={PDFstyles.columnData}>
									{new Date(t.added_time).toLocaleString()}
								</Text>

								<Text style={PDFstyles.columnData}>
									{convertCentsToCurrency(String(t.currency), t.txn_amount)}
								</Text>

								<Text style={PDFstyles.columnData}>
									{t.pan_masked || 'N/A'}
								</Text>
							</View>
						))}
					</View>
				</Page>
			</Document>
		);
	}

	return (
		<>
			<ReportButton onClick={handleGeneratePDF}>
				{fetchOperationalReportsQuery.isLoading ? (
					<Loader color='#fff' size={30} />
				) : (
					'Gerar relatório PDF'
				)}
			</ReportButton>
		</>
	);
}
