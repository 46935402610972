import Modal from '../../../../../../../../components/Modal';
import * as S from './style';
import { FaInfoCircle } from 'react-icons/fa';

interface Props {
	onResetEmailStatus: () => void;
}

export function SendEmailConfirmModal({ onResetEmailStatus }: Props) {
	return (
		<>
			<Modal
				isOpen={true}
				onRequestClose={() => {
					onResetEmailStatus();
				}}
				enableClose
			>
				<S.Container>
					<FaInfoCircle />
					<S.Message>Email de redefinição enviado com sucesso!</S.Message>
					<S.OptionsContainer>
						<S.ModelBttn
							onClick={() => {
								onResetEmailStatus();
							}}
						>
							Confirmar
						</S.ModelBttn>
					</S.OptionsContainer>
				</S.Container>
			</Modal>
		</>
	);
}
