import styled, { css } from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

interface buttonModel {
	buttonModel?: boolean;
}

export const Container = styled.div``;

export const openModalBttn = styled.button`
	background-color: var(--primary-blue);
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	padding: 0.8rem;
	margin: 0 auto;
	& svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}
`;

export const TitleWrapper = styled.div`
	margin-bottom: 1rem;
	flex-direction: row;
	display: flex;
	align-items: center;
	gap: 2rem;
`;

export const NavigationButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		background-color: var(--primary-blue);
		border: none;
		border-radius: 50%;
		width: 4rem;
		height: 4rem;
		padding: 0.8rem;
		margin: 0 0.5rem;
		cursor: pointer;
	}

	button svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}

	button:disabled {
		background-color: var(--gray-light);
		cursor: default;
	}
`;

export const BaseInputStyle = css`
	width: 100%;
	height: 4.8rem;
	color: var(--dark-gray2);
	background-color: #f0f3f8;
	border-radius: 4px;
	border: none;
	padding: 0 1.6rem;
	/* &:disabled {
		background: none;
		color: inherit;
		cursor: default;
	} */
`;

export const InputComp = styled.input`
	${BaseInputStyle}
	font-family: 'Poppins', sans-serif;
	font-size: 1.4rem;

	&::placeholder {
		color: var(--light-gray);
	}
	&:focus {
		outline: 1px solid var(--light-gray);
	}
`;

export const GridContainer = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	grid-column-gap: 3rem;
	grid-row-gap: 4rem;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	width: 100%;
	padding-bottom: 1rem;
	text-transform: uppercase;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--mid-gray);
	text-transform: uppercase;
	margin-bottom: 0.5rem;
`;

export const BttnContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
`;

export const ButtonWrapper = styled.div<buttonModel>`
	margin-bottom: 1rem;
	height: min-content;
	border-radius: 0.4rem;
	border: ${(props) =>
		props.buttonModel ? '0.2rem solid var(--primary-blue)' : 'none'};
	padding: 0.2rem 1rem;
	font-weight: bold;
	font-size: 1.4rem;
	background-color: ${(props) =>
		props.buttonModel ? 'var(--primary-blue)' : 'transparent'};
	color: ${(props) =>
		props.buttonModel ? 'var(--white)' : 'var(--primary-blue)'};
	margin-right: 2.4rem;
	cursor: pointer;
`;

export const CopyIdBtn = styled.button`
	${OptionsButton};
	height: 4rem;
	width: fit-content;
`;
