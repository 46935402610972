import { GroupCompany } from '../../@types/GroupCompany';
import api from '../api';


export async function fetctCorporateGroupCompany(
	corporate_group_id: string,
	company_id:string
): Promise<GroupCompany> {
	const { data } = await api.get<GroupCompany>(
		`/api/v1/cs/companies/${company_id}/${corporate_group_id}`
	);

	return data;
}
