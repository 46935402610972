import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
	CORPWAY_EXTRACT_KEY,
	getCardExtractByMonth,
} from '../../../../../services/queries/Extract';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import PageTitle from '../../../../../components/PageTitle';
import Loader from '../../../../../components/Loader';
import * as S from './styles';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import * as TableStyles from '../../../../../components/Table/TableStyles';
import {
	checkDeclineAndStrikeText,
	parseTransactionType,
} from '../../../../../utils/transactionUtils';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';
import { EmptyContent } from '../../../../../components/EmptyContent';
import { Transaction } from '../../../../../@types/CorporateExpenses/Transaction';
import { CardTransactionModal } from '../CardTransactionModal';
import { useCardExtractListStore } from '../../../../../stores/useCardExtractListStore';
import { Filter } from '../../../../../components/Filter';
import { useParams } from 'react-router-dom';
import { MovementsModal } from './MovementsModal';
import { getCardMovements } from '../../../../../services/queries/Cards';

export function ExtractCard() {
	const [activeViewer, setActiveViewer] = useState<boolean>(true);
	const { company_id, card_id, card_pan } = useParams();

	const [filtersParams, setFiltersParams] = useCardExtractListStore((state) => [
		state.filterParams,
		state.setFiltersParams,
		state.resetFilters,
	]);

	const queryClient = useQueryClient();
	const date = new Date();
	const [selectedMonth, setSelectedMonth] = useState(
		`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`
	);
	const currentMonth = `${date.getFullYear()}-${(date.getMonth() + 1)
		.toString()
		.padStart(2, '0')}`;

	const eighteenMonthsAgo = date;
	eighteenMonthsAgo.setMonth(eighteenMonthsAgo.getMonth() - 18);
	const minDate = new Date(2024, 0);

	const getExtractQuery = useQuery(
		[
			'extractCard',
			CORPWAY_EXTRACT_KEY,
			company_id,
			selectedMonth,
			card_id,
			activeViewer,
			filtersParams,
		],
		() =>
			getCardExtractByMonth(
				card_id!,
				company_id!,
				selectedMonth,
				activeViewer,
				filtersParams
			),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar o extrato. ');
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data } = useQuery(
		['cardMovements', company_id, card_id, selectedMonth],
		() => getCardMovements(company_id!!, card_id!!, selectedMonth),
		{
			onError: (err) => {
				console.error(
					err as Error,
					'Não foi possível buscar os movimentos do cartão.'
				);
			},
			enabled: !!selectedMonth,
			refetchOnWindowFocus: false,
		}
	);

	function checkMinDate() {
		if (eighteenMonthsAgo < minDate) {
			return minDate;
		} else {
			return eighteenMonthsAgo;
		}
	}

	function handleChangeMonthDate(increment: -1 | 1) {
		const [year, month] = selectedMonth.split('-').map(Number);

		const newDate = new Date(year, month - 1, 1);

		newDate.setMonth(newDate.getMonth() + increment);

		const minDate = checkMinDate();

		if (newDate >= minDate && newDate <= new Date()) {
			setSelectedMonth(
				`${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
					2,
					'0'
				)}`
			);
		}
	}

	const handleResetData = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth() + 1;
		setSelectedMonth(`${year}-${month.toString().padStart(2, '0')}`);
	};

	function refreshPage() {
		handleResetData();
		queryClient.resetQueries('extractCard');
	}

	function CardExtractRow({ transaction }: { transaction: Transaction }) {
		return (
			<TableStyles.TableRow>
				<TableStyles.TableData>
					{new Date(transaction.added_time).toLocaleString()}
				</TableStyles.TableData>
				<TableStyles.TableData>
					{parseTransactionType(
						transaction.txn_type,
						transaction.msg_type,
						transaction.response_code,
						transaction.currency,
						transaction.merchant_country
					)}
				</TableStyles.TableData>
				<TableStyles.TableData>
					{checkDeclineAndStrikeText(
						transaction.response_code,
						transaction.merchant_name
					)}
				</TableStyles.TableData>
				<TableStyles.TableData>
					{checkDeclineAndStrikeText(
						transaction.response_code,
						convertCentsToReais(transaction.billing_amount)
					)}
				</TableStyles.TableData>
				<TableStyles.TableData>
					<CardTransactionModal
						activeViewer={activeViewer}
						transaction={transaction}
						cardPan={card_pan!}
					/>
				</TableStyles.TableData>
			</TableStyles.TableRow>
		);
	}

	function CardExtractTable() {
		return (
			<>
				<TableStyles.TableHeaderContainer>
					<PageTitle title={`Extrato do cartão - ${card_pan}`} />

					<Filter
						filterParams={filtersParams}
						onFiltersChanged={(updatedFilters) => {
							setFiltersParams(updatedFilters);
						}}
					/>

					<div style={{ marginLeft: 'auto' }}></div>
					<S.NavigationButtons>
						<button
							onClick={() => handleChangeMonthDate(-1)}
							disabled={checkMinDate() >= new Date(`${selectedMonth}-01`)}
						>
							<MdKeyboardArrowLeft />
						</button>
						<S.InputComp
							type='month'
							value={selectedMonth}
							max={currentMonth}
							onChange={(e) => setSelectedMonth(e.target.value)}
							min={`${checkMinDate().getFullYear()}-${(
								checkMinDate().getMonth() + 1
							)
								.toString()
								.padStart(2, '0')}`}
							style={{ width: 'fit-content', fontWeight: 600 }}
						/>
						<button
							onClick={() => handleChangeMonthDate(1)}
							disabled={selectedMonth >= currentMonth}
						>
							<MdKeyboardArrowRight />
						</button>
					</S.NavigationButtons>

					<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyles.TableHeaderContainer>

				<MovementsModal data={data} />
				<S.BttnContainer>
					<S.ButtonWrapper
						buttonModel={activeViewer}
						onClick={() => setActiveViewer(true)}
					>
						Visão CS
					</S.ButtonWrapper>
					<S.ButtonWrapper
						buttonModel={!activeViewer}
						onClick={() => setActiveViewer(false)}
					>
						Visão Empresa
					</S.ButtonWrapper>
				</S.BttnContainer>

				{getExtractQuery.data && getExtractQuery.data.length > 0 ? (
					<TableStyles.Table>
						<TableStyles.TableHeader>
							<TableStyles.TableRow>
								<TableStyles.TableHeaderCell>DATA</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									LANÇAMENTO
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									ESTABELECIMENTO
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>VALOR</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									OPÇÕES
								</TableStyles.TableHeaderCell>
							</TableStyles.TableRow>
						</TableStyles.TableHeader>

						<TableStyles.TableBody>
							{getExtractQuery.data?.map((transaction) => (
								<CardExtractRow
									transaction={transaction}
									key={transaction.internal_transaction_id}
								/>
							))}
						</TableStyles.TableBody>
					</TableStyles.Table>
				) : (
					<EmptyContent big text='Sem dados de extrato para esse mês' />
				)}
			</>
		);
	}

	if (getExtractQuery.isError)
		return (
			<S.Container>
				<PageTitle title={`Extrato do cartão - ${card_pan}`} />
				<Loader />
			</S.Container>
		);

	return (
		<S.Container>
			{getExtractQuery.isLoading ? <Loader /> : <CardExtractTable />}
		</S.Container>
	);
}
