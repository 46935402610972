import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { EmptyContent } from '../../../../components/EmptyContent';
import { Filter } from '../../../../components/Filter';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import { Pagination } from '../../../../components/Pagination';
import * as TableStyles from '../../../../components/Table/TableStyles';
import { TextWithHint } from '../../../../components/TextWithHint';
import { getCollaborators } from '../../../../services/queries/Collaborators';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { cpfMask } from '../../../../utils/masks/cpf';
import { parseKYCStatusString } from '../../../../utils/status/parseKycStatus';
import { parseStatus } from '../../../../utils/status/parseStatus';
import { CardDetailsModal } from './components/CardDetailsModal';
import { CollaboratorDetailsModal } from './components/CollaboratorDetailsModal';
import * as S from './styles';
import { BiTransfer } from 'react-icons/bi';
import { useCollaboratorsListStore } from '../../../../stores/useCollaboratorsListStore';
import { useSetHistoryNonMatchCallbackLocation } from '../../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook/index';
import { SortColumnButton } from '../../../../components/SortColumnButton';
import { BalanceDetailsModal } from './components/BalanceDetailsModal';

export function CollaboratorsList() {
	const queryClient = useQueryClient();
	const [currentPage, setCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();

	const [filterParams, setFiltersParams, resetFilters] =
		useCollaboratorsListStore((state) => [
			state.filterParams,
			state.setFiltersParams,
			state.resetFilters,
		]);
	useSetHistoryNonMatchCallbackLocation('collaborators', resetFilters);

	const fetchCollaboratorsQuery = useQuery(
		['collaboratorsList', currentPage, filterParams, currentSortColumn],
		() => {
			return getCollaborators(currentPage, filterParams, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar os colaboradores.'
				);
			},
		}
	);

	function refreshPage() {
		setCurrentPage(1);
		queryClient.resetQueries('collaboratorsList');
	}

	if (fetchCollaboratorsQuery.isLoading)
		return (
			<S.Container>
				<PageTitle title='Colaboradores' />
				<Loader />
			</S.Container>
		);

	return (
		<S.Container>
			<TableStyles.TableHeaderContainer>
				<PageTitle
					title='Colaboradores'
					totalRecords={fetchCollaboratorsQuery.data?.totalCount}
				/>

				<Filter
					filterParams={filterParams}
					onFiltersChanged={(updatedFilters) => {
						setFiltersParams(updatedFilters);
						setCurrentPage(1);
					}}
				/>

				<Pagination
					onPageChange={(page) => setCurrentPage(page)}
					currentPage={currentPage}
					totalCount={fetchCollaboratorsQuery.data?.totalCount ?? 1}
				/>
				<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyles.TableHeaderContainer>

			{fetchCollaboratorsQuery.data?.totalCount! > 0 ? (
				<TableStyles.Table>
					<TableStyles.TableHeader>
						<TableStyles.TableRow>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'name'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('name')}
								/>
								NOME
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'email'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('email')}
								/>
								EMAIL
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'company'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('company')}
								/>
								EMPRESA
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								STATUS DO KYC
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>STATUS</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>CPF</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'office'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('office')}
								/>
								CARGO
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell style={{ textAlign: 'center' }}>
								OPÇÕES
							</TableStyles.TableHeaderCell>
						</TableStyles.TableRow>
					</TableStyles.TableHeader>

					<TableStyles.TableBody>
						{fetchCollaboratorsQuery.data?.collaborators.map((collaborator) => (
							<TableStyles.TableRow key={collaborator.id}>
								<TableStyles.TableData>
									<TextWithHint
										text={`${collaborator.first_name} ${collaborator.last_name}`}
										width='14rem'
									/>
								</TableStyles.TableData>
								<TableStyles.TableData>
									<TextWithHint text={collaborator.email} width='18rem' />
								</TableStyles.TableData>
								<TableStyles.TableData>
									<TextWithHint
										text={collaborator.company_name}
										width='14rem'
									/>
								</TableStyles.TableData>
								<TableStyles.TableData>
									<TableStyles.StatusContainer>
										<span>{parseKYCStatusString(collaborator.kyc_status)}</span>
									</TableStyles.StatusContainer>
								</TableStyles.TableData>
								<TableStyles.TableData>
									{parseStatus(collaborator.status)}
								</TableStyles.TableData>
								<TableStyles.TableData>
									<S.cpfText>{cpfMask(collaborator.cpf)}</S.cpfText>
								</TableStyles.TableData>
								<TableStyles.TableData>
									<TextWithHint text={`${collaborator.office}`} width='14rem' />
								</TableStyles.TableData>
								<TableStyles.TableData>
									<S.OptionsContainer>
										<CollaboratorDetailsModal collaborator={collaborator} />

										<S.OptionLinkButton
											data-rh='Transações'
											to={
												collaborator.kyc_status !== 'approved'
													? '#'
													: `${collaborator.id}/transactions/v2`
											}
											$disabled={collaborator.kyc_status !== 'approved'}
										>
											<BiTransfer />
										</S.OptionLinkButton>

										<CardDetailsModal card={collaborator.card_info} />

										<BalanceDetailsModal collaborator={collaborator} />
									</S.OptionsContainer>
								</TableStyles.TableData>
							</TableStyles.TableRow>
						))}
					</TableStyles.TableBody>
				</TableStyles.Table>
			) : (
				<EmptyContent big />
			)}
		</S.Container>
	);
}
