import { useState } from 'react';
import { useMutation } from 'react-query';
import { Collaborator } from '../../../../../../../@types/Collaborators';
import Modal from '../../../../../../../components/Modal';
import { sendForgotPasswordEmail } from '../../../../../../../services/queries/Collaborators';
import { showErrorMessage } from '../../../../../../../utils/ErrorHandler';
import * as S from './styles';
import PageTitle from '../../../../../../../components/PageTitle';
import * as FormStyles from '../../../../../../../components/Form/FormStyles';
import { SendEmailConfirmModal } from './SendEmailConfirmModal';

interface Props {
	collaborator: Collaborator;
}

export function ResendPasswordEmailModal({ collaborator }: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const [useNewEmail, setUseNewEmail] = useState(false);
	const [newEmail, setNewEmail] = useState('');
	const [selectOptions, setSelectOptions] = useState('');
	const [success, setSucess] = useState(false);

	const resendPasswordEmail = useMutation(
		() =>
			sendForgotPasswordEmail(
				newEmail ? newEmail : collaborator.email,
				collaborator.id
			),
		{
			onSuccess: () => {
				//toast.info('Email de redefinição enviado com sucesso!');
				setSucess(true);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar eviar o email de redefinição de senha ao colaborador.'
				);
			},
		}
	);

	const isValidEmail = (email: string) => {
		// Expressão regular para validar o formato do e-mail
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleSendEmail = () => {
		resendPasswordEmail.mutate();
	};

	function disableButton() {
		const isValidExistingEmail = selectOptions === 'existingEmail';
		const isValidNewEmail =
			selectOptions === 'newEmail' && isValidEmail(newEmail);
		const isLoading = resendPasswordEmail.isLoading;

		return !(isValidExistingEmail || isValidNewEmail) || isLoading;
	}

	// USE TO CLEAN
	const resetEmailStatus = () => {
		setUseNewEmail(false);
		setNewEmail('');
		setIsOpen(false);
		setSelectOptions('');
		setSucess(false);
	};

	return (
		<>
			<S.SendEmailBtn onClick={() => setIsOpen(true)}>
				Enviar email de redefinição de senha
			</S.SendEmailBtn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => resetEmailStatus()}
				enableClose
			>
				<S.Container>
					<S.TitleWrapper>
						{' '}
						<PageTitle
							title={`Enviar email de recuperação de senha do colaborador: ${collaborator.first_name} ${collaborator.last_name}`}
						/>
					</S.TitleWrapper>
					<S.InfoLabel>
						Como você gostaria de receber o email de recuperação?
					</S.InfoLabel>

					<FormStyles.SelectInput
						value={selectOptions}
						onChange={(e) => {
							const selectedValue = e.target.value;
							if (selectedValue === 'newEmail') {
								setUseNewEmail(true);
							} else if (selectedValue === 'existingEmail') {
								setUseNewEmail(false);
							}
							setSelectOptions(selectedValue);
						}}
					>
						<option value='' disabled>
							Selecione uma opção
						</option>
						<option value='existingEmail'>Usar email cadastrado</option>
						<option value='newEmail'>Fornecer novo email</option>
					</FormStyles.SelectInput>

					{useNewEmail && (
						<>
							<S.InfoLabel>Digite um email valido:</S.InfoLabel>
							<S.InputEmail
								type='email'
								value={newEmail}
								onChange={(e) => setNewEmail(e.target.value)}
							/>
						</>
					)}

					<S.SendEmailBtn disabled={disableButton()} onClick={handleSendEmail}>
						Enviar
					</S.SendEmailBtn>
					{success && (
						<>
							<SendEmailConfirmModal onResetEmailStatus={resetEmailStatus} />
						</>
					)}
				</S.Container>
			</Modal>
		</>
	);
}
