import { useEffect, useState } from 'react';
import { Company } from '../../../../../../../@types/Company';
import { useQuery } from 'react-query';
import { showErrorMessage } from '../../../../../../../utils/ErrorHandler';
import * as S from './styles';
import { Pagination } from '../../../../../../../components/Pagination';
import PageTitle from '../../../../../../../components/PageTitle';
import * as TableStyles from '../../../../../../../components/Table/TableStyles';
import Modal from '../../../../../../../components/Modal';
import Loader from '../../../../../../../components/Loader';
import { EmptyContent } from '../../../../../../../components/EmptyContent';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { getCompaniesWithBenefits } from '../../../../../../../services/queries/Companies';
import { cnpjMask } from '../../../../../../../utils/masks/cnpj';
import { filterParams as companyFilterParams } from '../../../../../../../stores/useCompaniesListStore';
import { Filter, FilterParams } from '../../../../../../../components/Filter';

interface Props {
	selectedCompany: Company | null;
	onSelectCompany: (Company: Company | null) => void;
	isDisable?: boolean;
}

export function CompanySelector({
	selectedCompany,
	onSelectCompany,
	isDisable,
}: Props) {
	const [isOpen, setOpen] = useState(false);
	const [companyCurrentPage, setCompanyCurrentPage] = useState(1);
	const [filterParams, setFilterParams] = useState(companyFilterParams);

	const fetchCompaniesQuery = useQuery(
		// ['companiesList', currentPage, filtersParams, currentSortColumn],
		['companiesList', companyCurrentPage, filterParams],
		() => getCompaniesWithBenefits(companyCurrentPage, filterParams, null),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as empresas.'
				);
			},
			enabled: isOpen,
		}
	);

	useEffect(() => {
		if (isOpen) {
			// reseting
			setCompanyCurrentPage(1);
		}
	}, [isOpen]);

	function handleClose() {
		setOpen(false);
	}

	function updateFilters(filters: FilterParams[]) {
		setCompanyCurrentPage(1);
		setFilterParams(filters);
	}

	function handleToggleSelectCompany(Company: Company | null) {
		onSelectCompany(Company);
		handleClose();
	}

	if (!fetchCompaniesQuery.data) {
		return (
			<div>
				<S.MainButton
					onClick={() => setOpen(true)}
					type='button'
					disabled={isDisable}
				>
					Selecionar Empresa
				</S.MainButton>
				<Modal isOpen={isOpen} enableClose onRequestClose={handleClose}>
					<S.Container data-testid='CollabsSelector-modal-container'>
						<TableStyles.TableHeaderContainer>
							<PageTitle title='Selecione a empresa' />
						</TableStyles.TableHeaderContainer>
						<Loader />
					</S.Container>
				</Modal>
			</div>
		);
	}

	return (
		<div>
			<S.MainButton
				onClick={() => setOpen(true)}
				type='button'
				disabled={isDisable}
			>
				Selecionar Empresa
			</S.MainButton>
			<Modal isOpen={isOpen} enableClose onRequestClose={handleClose}>
				<S.Container data-testid='CollabsSelector-modal-container'>
					<TableStyles.TableHeaderContainer>
						<PageTitle title='Selecione a empresa' />
						<Filter
							filterParams={filterParams}
							onFiltersChanged={updateFilters}
						/>
						<Pagination
							onPageChange={(page) => setCompanyCurrentPage(page)}
							currentPage={companyCurrentPage}
							totalCount={fetchCompaniesQuery.data.totalCompanies}
						/>
					</TableStyles.TableHeaderContainer>
					<S.CompaniesList>
						{fetchCompaniesQuery.data.companies.map((company) => (
							<S.CompanyContainer key={company.id}>
								<S.CompanyCard>
									<S.CompanyInfoContainer>
										<S.CompanyName>{company.name}</S.CompanyName>
										<S.CompanyInfo>{cnpjMask(company.cnpj)}</S.CompanyInfo>
										<S.CompanyInfo>{company.email}</S.CompanyInfo>
									</S.CompanyInfoContainer>
								</S.CompanyCard>

								{selectedCompany?.id === company.id ? (
									<S.Selector
										selected
										onClick={() => handleToggleSelectCompany(null)}
									>
										<RiCloseCircleLine />
									</S.Selector>
								) : (
									<S.Selector
										selected={false}
										onClick={() => handleToggleSelectCompany(company)}
									>
										<RiAddCircleLine />
									</S.Selector>
								)}
							</S.CompanyContainer>
						))}

						{fetchCompaniesQuery.data.companies.length === 0 && (
							<EmptyContent text='Nenhum empresa disponível' />
						)}
					</S.CompaniesList>
				</S.Container>
			</Modal>
		</div>
	);
}
