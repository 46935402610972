import { useState } from "react";
import { useQuery } from "react-query";
import { BiSpreadsheet } from "react-icons/bi";
import * as S from './styles';
import Modal from "../../../../components/Modal";
import PageTitle from "../../../../components/PageTitle";
import { TabPanel, Tabs } from "react-tabs";
import * as TabStyle from '../../../../components/Tab/TabStyles';
import { OperatorsTab } from "./GeneralData/OperatorsTab";
import { fetctCorporateGroupCompany } from "../../../../services/queries/GroupCompanies";
import { showErrorMessage } from "../../../../utils/ErrorHandler";
import Loader from "../../../../components/Loader";
import { GroupCompanyTab } from "./GeneralData/GroupCompanyTab";

interface GroupCompanyDetailsProps {
  corporate_group_id: string;
  company_id: string;
}

export function GroupCompanyDetails({ corporate_group_id, company_id }: GroupCompanyDetailsProps) {
  const [isOpen, setIsOpen] = useState(false);

  const fetchGroupCompanyQuery = useQuery(
    ['fetchCorporateGroup', corporate_group_id, company_id],
    () => {
      if (!corporate_group_id || !company_id) {
        throw new Error('Os IDs do grupo corporativo e da empresa são obrigatórios.');
      }
      return fetctCorporateGroupCompany(corporate_group_id, company_id);
    },
    {
      onError: (err) => {
        showErrorMessage(
          err as Error,
          'Ocorreu um problema ao buscar informações do grupo corporativo. '
        );
      },
    }
  );

  const groupCompanyData = fetchGroupCompanyQuery.data;

  return (
    <>
      <S.openModalBttn
        onClick={() => setIsOpen(true)}
        data-rh='Grupo corporativo'
        data-testid='openButton_test_id'
      >
        <BiSpreadsheet />
      </S.openModalBttn>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        enableClose
      >
        <S.Container>
          <S.TitleWrapper>
            <PageTitle
              title={`Detalhes do corporativo da empresa`}
            />
          </S.TitleWrapper>
          <Tabs>
            <TabStyle.TabListContainer>
              <TabStyle.TabCustomized>Dados Gerais</TabStyle.TabCustomized>
              <TabStyle.TabCustomized>Operadores</TabStyle.TabCustomized>
            </TabStyle.TabListContainer>

            <TabPanel>
              {fetchGroupCompanyQuery.isLoading ? (
                <S.LoadingContainer>
                  <Loader size={30} />
                </S.LoadingContainer>
              ) : (
                <GroupCompanyTab 
                  name={groupCompanyData?.name!} 
                  companies={groupCompanyData?.companiesQuantity!} 
                />
              )}
            </TabPanel>
            <TabPanel>
              {fetchGroupCompanyQuery.isLoading ? (
                <S.LoadingContainer>
                  <Loader size={30} />
                </S.LoadingContainer>
              ) : (
                  <OperatorsTab
                    operators={groupCompanyData?.operators || []}
                  />
              )}
            </TabPanel>
          </Tabs>
        </S.Container>
      </Modal>
    </>
  );
}
