import { RequestDeleteUsersCollaborator } from '../../../../../../../@types/RequestDeleteUsersCollaborator';
import { cpfMask } from '../../../../../../../utils/masks/cpf';
import * as S from './styles';

interface Props {
	request: RequestDeleteUsersCollaborator;
}

export function RequestData({ request }: Props) {
	return (
		<>
			<S.GridContainer>
				<S.InfoContainer>
					<S.InfoLabel>NOME EMPRESA</S.InfoLabel>
					{`${request.company.name}`}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>NOME OPERADOR SOLICITANTE</S.InfoLabel>
					{`${request.operator.name}`}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>NOME USUÁRIO</S.InfoLabel>
					{`${request.user.first_name} ${request.user.last_name}`}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>CPF USUÁRIO</S.InfoLabel>
					{`${cpfMask(request.user.cpf)}`}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>MOTIVO DA SOLICITAÇÃO</S.InfoLabel>
					{request.reason_deletion}
				</S.InfoContainer>

				{request.reviwed && (
					<S.InfoContainer>
						<S.InfoLabel>STATUS REVISÃO</S.InfoLabel>
						{request.deleted ? 'Aprovado' : 'Reprovado'}
					</S.InfoContainer>
				)}

				{request.reason_reviwed && (
					<S.InfoContainer>
						<S.InfoLabel>MOTIVO DA REJEIÇÃO</S.InfoLabel>
						{request.reason_reviwed}
					</S.InfoContainer>
				)}
			</S.GridContainer>
		</>
	);
}
