import styled from 'styled-components';
import { OptionsButton } from '../../../../../../../components/Button';

export const Container = styled.div`
	width: 40vw;
	height: 40vh;
	padding: 1rem 0;
`;

export const SendEmailBtn = styled.button`
	${OptionsButton}
	margin-top: 2rem;
	height: 4rem;
	width: fit-content;
`;

export const BttnContainer = styled.div`
	padding-bottom: 2rem;
	display: flex;
	column-gap: 1rem;
`;

export const InfoLabel = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--mid-gray);
	text-transform: uppercase;
	margin-bottom: 0.5rem;
	margin-top: 1rem;
`;

export const TitleWrapper = styled.div`
	margin-bottom: 1rem;
`;

export const InputEmail = styled.input`
	padding: 0.5rem;
	font-size: 1.6rem;
	border: 0.1rem solid #ccc;
	border-radius: 0.25rem;
	width: 100%;
	margin-bottom: 1rem;
`;
