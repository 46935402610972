import { useState } from "react";
import * as S from './styles'
import { MdNotes } from "react-icons/md";
import Modal from "../../../../../components/Modal";
import PageTitle from "../../../../../components/PageTitle";
import { TabPanel, Tabs } from "react-tabs";
import * as TableStyles from "../../../../../components/Tab/TabStyles";
import { toast } from "react-toastify";
import { checkCorpwayCardStatus } from "../../../../../utils/parseCropwayCardStatus";
import { Card } from "../../../../../@types/CorporateExpenses/Card";

export interface Props {
    corpwayCard: Card;
}

export function CardDetailsModal({ corpwayCard }: Props) {
    const [isOpen, setIsOpen] = useState(false);

    const copyIdToClipboard = (ID: string, BAG_ID: string, TAH_ID: string) => {
        const idString = `ID = ${ID}\nBAG_ID = ${BAG_ID}\nTAH_ID = ${TAH_ID}`;
        navigator.clipboard.writeText(idString)
    }

    const handleCopyClick = () => {
        copyIdToClipboard(
            corpwayCard.baas_card_id,
            corpwayCard.baas_account_id,
            corpwayCard.company_data.id_baas
        )

        toast.info('IDs copiados para a área de transferência.');
    }

    return (
        <>
            <S.OpenModalBttn
                onClick={() => setIsOpen(true)}
                data-rh='Detalhes'
                data-testid='openButton_test_id'
            >
                <MdNotes />
            </S.OpenModalBttn>

            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                enableClose
            >
                <S.Container>
                    <S.TitleWrapper>
                        <PageTitle
                            title={`Dados do cartão: ${corpwayCard.pan}`}
                        />
                    </S.TitleWrapper>
                    <Tabs>
                        <TableStyles.TabListContainer>
                            <TableStyles.TabCustomized>Dados do cartão</TableStyles.TabCustomized>
                        </TableStyles.TabListContainer>

                        <TabPanel>
                            <>
                                <S.GridContainer>
                                    <S.LeftColumn>
                                        <S.InfoContainer>
                                            <S.InfoLabel>ID do cartão</S.InfoLabel>
                                            <span>{corpwayCard.baas_card_id}</span>
                                        </S.InfoContainer>
                                        <S.InfoContainer>
                                            <S.InfoLabel>ID da conta</S.InfoLabel>
                                            <span>{corpwayCard.baas_account_id}</span>
                                        </S.InfoContainer>
                                        <S.InfoContainer>
                                            <S.InfoLabel>ID Empresa SWAP</S.InfoLabel>
                                            <span>{corpwayCard.company_data.id_baas}</span>
                                        </S.InfoContainer>
                                        <S.BttnContainer>
                                            <S.CopyIdBtn onClick={handleCopyClick}>Copiar IDs</S.CopyIdBtn>
                                        </S.BttnContainer>
                                    </S.LeftColumn>

                                    <S.RightColumn>
                                        <S.InfoContainer>
                                            <S.InfoLabel>APELIDO</S.InfoLabel>
                                            <span>{corpwayCard.alias}</span>
                                        </S.InfoContainer>
                                        <S.InfoContainer>
                                            <S.InfoLabel>PAN</S.InfoLabel>
                                            <span>{corpwayCard.pan}</span>
                                        </S.InfoContainer>
                                        <S.InfoContainer>
                                            <S.InfoLabel>STATUS</S.InfoLabel>
                                            <span>{checkCorpwayCardStatus(corpwayCard.status)}</span>
                                        </S.InfoContainer>
                                        <S.InfoContainer>
                                            <S.InfoLabel>EMPRESA</S.InfoLabel>
                                            <span>{corpwayCard.company_data.name}</span>
                                        </S.InfoContainer>
                                        <S.InfoContainer>
                                            <S.InfoLabel>CENTRO DE CUSTO</S.InfoLabel>
                                            <span>{corpwayCard.cost_center_data?.title}</span>
                                        </S.InfoContainer>
                                    </S.RightColumn>
                                </S.GridContainer>
                            </>
                        </TabPanel>
                    </Tabs>
                </S.Container>
            </Modal>
        </>
    )
}