import { FormEvent, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { UF_List } from '../../utils/UF';
import { cpfMask, parseMaskedCPFToRaw } from '../../utils/masks/cpf';
import * as FormStyles from '../Form/FormStyles';
import * as S from './styles';
import { cnpjMask, parseMaskedCnpjToRaw } from '../../utils/masks/cnpj';
import { useQuery } from 'react-query';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { getCompaniesSelect } from '../../services/queries/Companies';
import { Company } from '../../@types/Company';
import { parseInputToDateMMDD } from '../../utils/parseDate';
import {
	getFirstDayOfMonth,
	getLastDayOfMonth,
	getMaxMonthValue,
	getMinMonthValue,
} from './utils/monthFilter';

export interface SelectOption {
	value: string;
	text: string;
}
export type FilterParams = {
	filter: string;
	name: string;
	value: string;
	second_value?: string;
	type:
		| 'text'
		| 'cpf'
		| 'cnpj'
		| 'email'
		| 'uf'
		| 'company'
		| 'company_id'
		| 'select'
		| 'group_company'
		| 'date_interval'
		| 'month_period'
		| 'scheduled_interval'
		| 'toggle'
		| 'day'
		| 'pan';
	selectOptions?: SelectOption[]; // used when type === 'select'
	selected: boolean;
	alwaysFilled?: boolean;
};

export interface FilterProps {
	filterParams: FilterParams[];
	onFiltersChanged: (updatedFilters: FilterParams[]) => void;
}

export function parseFilterParamsToParams(filterParams: FilterParams[]) {
	const params: any = {};
	filterParams
		.filter((f) => !!f.value)
		.forEach((filter) => {
			if (filter.type === 'date_interval') {
				params['start_date'] = parseInputToDateMMDD(filter.value);
				params['end_date'] = parseInputToDateMMDD(filter.second_value!);
			} else if (filter.type === 'scheduled_interval') {
				params['scheduled_start'] = parseInputToDateMMDD(filter.value);
				params['scheduled_end'] = parseInputToDateMMDD(filter.second_value!);
			} else if (filter.type === 'month_period') {
				const firstDayOfMonth = getFirstDayOfMonth(filter.value);
				const lastDayOfMonth = getLastDayOfMonth(filter.value);

				params['start_date'] = parseInputToDateMMDD(
					filter.value + '-' + firstDayOfMonth
				);
				params['end_date'] = parseInputToDateMMDD(
					filter.value! + '-' + lastDayOfMonth
				);
			} else {
				params[filter.filter] = filter.value;
			}
		});
	return params;
}

export function Filter({ filterParams, onFiltersChanged }: FilterProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [filters, setFilters] = useState(filterParams);

	const fetchCompaniesQuery = useQuery(
		['companiesListSelect'],
		() => {
			return getCompaniesSelect();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as empresas.'
				);
			},
			enabled: !!filterParams.find(
				(f) => f.type === 'company' || f.type === 'company_id'
			),
			refetchOnWindowFocus: false,
		}
	);

	function toggleFilterSelection(filter: string) {
		setFilters(
			filters.map((f) => {
				if (f.alwaysFilled) return f;

				if (f.filter === filter) {
					if (f.type === 'date_interval' || f.type === 'scheduled_interval') {
						return { ...f, selected: !f.selected, value: '', second_value: '' };
					}
					return { ...f, selected: !f.selected, value: '' };
				}

				return f;
			})
		);
	}

	function handleFilterChangeValue(
		filter: string,
		value: string,
		start_date?: boolean
	) {
		setFilters(
			filters.map((f) => {
				if (f.filter === filter) {
					if (f.type === 'date_interval' || f.type === 'scheduled_interval') {
						if (start_date) return { ...f, value };
						return { ...f, second_value: value };
					}
					if (f.type === 'toggle') {
						if (value === 'false') {
							return { ...f, value: '' };
						}

						return { ...f, value };
					}
					return { ...f, value };
				}
				return f;
			})
		);
	}

	function renderProperInput(filter: FilterParams) {
		if (filter.type === 'select') {
			return (
				<FormStyles.SelectInput
					value={filter.value}
					onChange={(e) => {
						handleFilterChangeValue(filter.filter, e.target.value);
					}}
				>
					<option value='' disabled>
						Selecione um tipo
					</option>
					{filter.selectOptions?.map((option) => (
						<option key={option.value} value={option.value}>
							{option.text}
						</option>
					))}
				</FormStyles.SelectInput>
			);
		}

		if (filter.type === 'cpf') {
			return (
				<FormStyles.SearchInput
					value={cpfMask(filter.value)}
					onChange={(e) => {
						const { value } = e.target;
						handleFilterChangeValue(filter.filter, parseMaskedCPFToRaw(value));
					}}
					placeholder={`${filter.name.toUpperCase()}`}
				/>
			);
		}

		if (filter.type === 'cnpj') {
			return (
				<FormStyles.SearchInput
					value={cnpjMask(filter.value)}
					onChange={(e) => {
						const { value } = e.target;
						handleFilterChangeValue(filter.filter, parseMaskedCnpjToRaw(value));
					}}
					placeholder={`${filter.name.toUpperCase()}`}
				/>
			);
		}

		if (filter.type === 'uf') {
			return (
				<FormStyles.SelectInput
					defaultValue={filter.value || ''}
					onChange={(e) => {
						handleFilterChangeValue(filter.filter, e.target.value);
					}}
				>
					<option disabled value=''>
						Selecione uma opção
					</option>
					{UF_List.map((uf) => (
						<option key={uf} value={uf}>
							{uf}
						</option>
					))}
				</FormStyles.SelectInput>
			);
		}

		if (filter.type === 'company' || filter.type === 'company_id') {
			return (
				<FormStyles.SelectInput
					value={filter.value}
					onChange={(e) => {
						handleFilterChangeValue(filter.filter, e.target.value);
					}}
				>
					<option value='' disabled>
						Selecione uma empresa
					</option>
					{fetchCompaniesQuery.data?.map((company: Company) => (
						<option
							key={company.id}
							value={filter.type === 'company' ? company.name : company.id}
						>
							{company.name}
						</option>
					))}
				</FormStyles.SelectInput>
			);
		}

		if (filter.type === 'pan') {
			return (
				<FormStyles.Input
					value={filter.value}
					onChange={(e) => {
						const value = e.target.value.slice(0, 4);
						handleFilterChangeValue(filter.filter, value);
					}}
					placeholder={`Digite os 4 últimos digitos`}
					maxLength={4}
					type='number'
				/>
			);
		}

		if (
			filter.type === 'date_interval' ||
			filter.type === 'scheduled_interval'
		) {
			return (
				<div style={{ marginTop: '5px' }}>
					<S.InputLabel>Data inicial</S.InputLabel>
					<FormStyles.Input
						type={'date'}
						value={filter.value}
						onChange={(e) => {
							handleFilterChangeValue(filter.filter, e.target.value, true);
						}}
						data-testid='start_date_input'
						required
					/>
					<div style={{ marginTop: '3px' }}></div>
					<S.InputLabel>Data final</S.InputLabel>
					<FormStyles.Input
						type={'date'}
						value={filter.second_value}
						onChange={(e) => {
							handleFilterChangeValue(filter.filter, e.target.value, false);
						}}
						data-testid='end_date_input'
						required
					/>
				</div>
			);
		}

		if (filter.type === 'month_period')
			return (
				<div style={{ marginTop: '5px' }}>
					<FormStyles.Input
						type={'month'}
						value={filter.value}
						pattern='[0-9]{4}-[0-9]{2}'
						placeholder='Ex. 1999-01'
						onChange={(e) => {
							handleFilterChangeValue(filter.filter, e.target.value, true);
						}}
						min={getMinMonthValue()}
						max={getMaxMonthValue()}
						data-testid='month_input'
						required
					/>
				</div>
			);

		if (filter.type === 'toggle') {
			return (
				<S.CheckBoxWrapper>
					<S.CheckBox
						id={`toggle-${filter.filter}`}
						type='checkbox'
						checked={filter.value === 'true' ? true : false}
						onChange={(e) => {
							handleFilterChangeValue(
								filter.filter,
								e.target.checked.toString()
							);
						}}
					/>
					<S.CheckBoxLabel htmlFor={`toggle-${filter.filter}`} />
				</S.CheckBoxWrapper>
			);
		}

		if (filter.type === 'day') {
			return (
				<FormStyles.Input
					type="number"
					min={1}
					max={31}
					value={filter.value}
					onChange={(e) => {
						handleFilterChangeValue(filter.filter, e.target.value);
					}}
					placeholder="Ex: 15"
				/>
			);
		}

		return (
			<FormStyles.SearchInput
				value={filter.value}
				onChange={(e) => {
					handleFilterChangeValue(filter.filter, e.target.value);
				}}
				placeholder={filter.name}
				// those params change based on filter.type as well
				type={filter.type === 'email' ? 'email' : 'text'}
			/>
		);
	}

	function handleClearFilters() {
		const clearedFilters = filters.map((f) => {
			if (f.alwaysFilled) return f;

			return { ...f, selected: false, value: '' };
		});
		setFilters(clearedFilters);
		onFiltersChanged(clearedFilters);
	}

	function handleSubmitFilter(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();
		onFiltersChanged(filters);
	}

	return (
		<Popover
			isOpen={isOpen}
			onClickOutside={() => setIsOpen(false)}
			positions={['bottom']} // preferred positions by priority
			content={({ position, childRect, popoverRect }) => (
				<ArrowContainer
					position={position}
					childRect={childRect}
					popoverRect={popoverRect}
					arrowColor={'var(--light-gray)'}
					arrowSize={6}
					arrowStyle={{ opacity: 0.7 }}
				>
					<S.Container data-testid='Filter-container-popover'>
						<S.Section>
							<S.SectionTitle>Adicionar filtros</S.SectionTitle>
							<S.FiltersContainer>
								{filters.map((filter) => (
									<S.SelectFilter
										onClick={() => toggleFilterSelection(filter.filter)}
										selected={filter.selected}
										key={filter.filter}
									>
										{filter.name}
									</S.SelectFilter>
								))}
							</S.FiltersContainer>
						</S.Section>

						<form onSubmit={(e) => handleSubmitFilter(e)}>
							<S.Section>
								<S.SectionTitle>Filtros</S.SectionTitle>

								<S.FiltersInputContainer>
									{filters
										.filter((f) => f.selected)
										.map((filter) => (
											<div key={filter.filter}>
												<S.InputLabel>{filter.name}</S.InputLabel>
												{renderProperInput(filter)}
											</div>
										))}
								</S.FiltersInputContainer>
							</S.Section>

							<S.FilterButton type='submit'>FILTRAR</S.FilterButton>
						</form>
						{/* <S.Section>
            <S.SectionTitle>Status</S.SectionTitle>
          </S.Section> */}
					</S.Container>
				</ArrowContainer>
			)}
		>
			<S.OpenFilterPopoverContainer>
				<S.OpenFilterButton onClick={() => setIsOpen(!isOpen)}>
					<FaFilter />
					<span>Filtros</span>
				</S.OpenFilterButton>
				{!!filterParams.filter((f) => !!f.value).length && (
					<S.FilterIndicator
						onClick={handleClearFilters}
						data-rh='Limpar filtros'
						data-testid='clear-filters-btn'
					>
						<span>{filterParams.filter((f) => !!f.value).length}</span>
						<RiCloseLine />
					</S.FilterIndicator>
				)}
			</S.OpenFilterPopoverContainer>
		</Popover>
	);
}
