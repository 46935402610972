import {
	Navigate,
	Route,
	Routes as RouterRoutes,
	useLocation,
} from 'react-router-dom';
import { CollaboratorsList } from '../pages/Main/FlexibleBenefits/Collaborators';
import { TransactionsV2 as CollaboratorTransactionsV2 } from '../pages/Main/FlexibleBenefits/Collaborators/TransactionsV2';
import { CompaniesList } from '../pages/Main/Companies';
import { OperationalReport } from '../pages/Main/FlexibleBenefits/OperationalReport';
import { Releases } from '../pages/Main/FlexibleBenefits/Release';
import { ReleasesDetails } from '../pages/Main/FlexibleBenefits/Release/ReleaseDetails';
import { CorpwayCards } from '../pages/Main/CorporateExpenses/CorpwayCards';
import { ExtractCard } from '../pages/Main/CorporateExpenses/CorpwayCards/CardsExtract';
import { CollaboratorsMigrations } from '../pages/Main/FlexibleBenefits/CollaboratorsMigrations';
import { CreateCollaboratorMigration } from '../pages/Main/FlexibleBenefits/CollaboratorsMigrations/CreateCollaboratorMigration';
import { CollaboratorMigrationDetails } from '../pages/Main/FlexibleBenefits/CollaboratorsMigrations/CollaboratorMigrationDetails';
import { RequestsPending } from '../pages/Main/FlexibleBenefits/Requests/RequestsPending';
import { RequestsAccepts } from '../pages/Main/FlexibleBenefits/Requests/RequestsAccepts';

export default function MainRoutes() {
	let location = useLocation();

	return (
		<RouterRoutes>
			<Route
				path={`/`}
				element={
					<Navigate to='/collaborators' state={{ from: location }} replace />
				}
			/>

			<Route path={`/collaborators`} element={<CollaboratorsList />} />
			<Route
				path={`/collaborators/:id/transactions/v2`}
				element={<CollaboratorTransactionsV2 />}
			/>

			<Route
				path={`/collaborators-migrations`}
				element={<CollaboratorsMigrations />}
			/>
			<Route
				path={`/collaborators-migrations/create`}
				element={<CreateCollaboratorMigration />}
			/>
			<Route
				path={`/collaborators-migrations/:id`}
				element={<CollaboratorMigrationDetails />}
			/>

			{/* <Route path={'/group-companies'} element={<GroupCompanies />} />
			<Route path={`/group-companies/:id`} element={<GroupCompanyDetails />} /> */}

			<Route path={`/companies`} element={<CompaniesList />} />

			<Route path={'/releases'} element={<Releases />} />
			<Route path={'/releases/:id'} element={<ReleasesDetails />} />

			<Route path={'/report'} element={<OperationalReport />} />

			<Route path={'/requests_pending'} element={<RequestsPending />} />

			<Route path={'/requests_accepts'} element={<RequestsAccepts />} />

			<Route path={'/corpway-cards'} element={<CorpwayCards />} />
			<Route
				path={`/corpway-cards/:company_id/extract-card/:card_id/:card_pan`}
				element={<ExtractCard />}
			/>

			{/* <Route path={'/transactions'} element={<Transactions />} /> */}

			<Route
				path={`*`}
				element={<Navigate to='/404' state={{ from: location }} replace />}
			/>
		</RouterRoutes>
	);
}
